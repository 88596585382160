<template>
  <customer-view-template>
    <stepper />
    <div class="confirm-protocol">
      <div class="produtos">
        <div
          v-for="dados_item in dados_itens"
          v-bind:key="dados_item.sequenciaFat"
          class="produto-container"
        >
          <div class="titulo">
            <h3>{{ $t('etapas.produto.tituloProdutoTro') }}:</h3>
          </div>
          <div class="produto">
            <div>
              <b>{{ $t('etapas.produto.produto') }}:</b
              ><span>{{ dados_item.produtoNome }}</span>
            </div>
            <div>
              <b>{{ $t('etapas.produto.cor') }}:</b
              ><span>{{ dados_item.produtoCor }}</span>
            </div>
            <div>
              <b>{{ $t('etapas.produto.tamanho') }}:</b
              ><span>{{ dados_item.gradeTamanho }}</span>
            </div>
            <hr v-if="picture_list.length > 0" />
            <div v-if="picture_list.length > 0" class="picture-preview">
              <h3>
                {{ $t('etapas.fotos.fotosAnexadas') }}:
                {{ picture_list.length }}
              </h3>
              <div class="preview">
                <div
                  class="picture-container"
                  v-for="(picture, index) in picture_list"
                  v-bind:key="index"
                > 
                <div class="picture">                    
                    <img :src="picture.object_url" alt="" />
                  </div>
                </div>
              </div>              
            </div>
            <hr v-if="verifyHasObjectValuesAndKeys(this.bankData) && dados_item.pedTipoPgto == this.getTypeBoleto()" />
            <div v-if="verifyHasObjectValuesAndKeys(this.bankData) && dados_item.pedTipoPgto == this.getTypeBoleto()"  class="produto">
                <div>
                  <h3>{{ $t('dass.bankData') }}:</h3>
                </div>
                <div>
                  <b>{{ $t('etapas.comprador.agencia') }}:</b>
                  <span>{{ this.bankData.cli_agencia }}</span>
                </div>
                <div>
                  <b>{{ $t('etapas.comprador.conta') }}:</b>
                  <span>{{ this.bankData.cli_conta }}</span>
                </div>
                <div>
                  <b>{{ $t('etapas.comprador.chavePix') }}:</b>
                  <span>{{ this.bankData.cli_chavepix }}</span>
                </div>
                <div>
                  <b>{{ $t('etapas.comprador.codigoBanco') }}:</b>
                  <span>{{ this.bankData.ban_codigo }}</span>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="endereco">
        <h3>{{ $t('etapas.comprador.tituloEntrega') }}:</h3>
        <div>
          <b>{{ $t('etapas.comprador.cep') }}:</b>
          <span>{{ dados_gerais.cepCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.endereco') }}:</b>
          <span>{{ dados_gerais.enderecoCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.numero') }}:</b>
          <span>{{ dados_gerais.numeroEndrCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.complemento') }}:</b>
          <span>{{ dados_gerais.complementoEndrCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.bairro') }}:</b>
          <span>{{ dados_gerais.bairroCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.cidade') }}:</b>
          <span>{{ dados_gerais.cidadeCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.uf') }}:</b>
          <span>{{ dados_gerais.UFCliente }}</span>
        </div>
        <hr />
        <h3>{{ $t('etapas.comprador.tituloDestinatario') }}:</h3>
        <div>
          <b>{{ $t('etapas.comprador.nome') }}:</b>
          <span>{{ dados_gerais.nomeCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.email') }}:</b>
          <span>{{ dados_gerais.emailCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.cpf') }}:</b>
          <span>{{ dados_gerais.cpfCliente }}</span>
        </div>
        <div>
          <b>{{ $t('etapas.comprador.telefone') }}:</b>
          <span>{{ dados_gerais.telefoneCliente }}</span>
        </div>
      </div>
      <div class="actions">
        <dass-button
          :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"
          :id="`dass-button-tertiary-close-${this.brandName}`"
          variant="tertiary"
          type="button"
          @click="cancel"
        >
          <dass-icon token="icon-content-clear" size="30"></dass-icon>
          {{ $t('dass.cancelar') }}
        </dass-button>

        <dass-button
          :class="this.isSafari ? `dass-button-primary-${this.brandName} button` : 'button'"
          variant="primary"
          type="button"
          @click="next"
        >
          <dass-spinner 
          v-if="this.isLoading"
          size="24"
          />
          {{ $t('etapas.comprador.confirmar') }}
          <dass-icon v-if="!this.isLoading" token="icon-action-done_all" size="30"></dass-icon>
        </dass-button>
      </div>
    </div>
  </customer-view-template>
</template>

<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import Stepper from '../components/organisms/customer_view_template/Stepper'
import { typesPayment } from '../helpers/constants/typesPayment'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConfirmProtocol',
  props: {
    ecommerce: Boolean,
  },
  components: { CustomerViewTemplate, Stepper },
  data() {
    return {
      isSafari: false,
      brandName: '',
      isLoading: false
    }
  },
  methods: {
    ...mapActions('create_protocol', [
      'setCurrentStep',
      'generateProtocol',
      'uploadFileList',
    ]),
    ...mapActions('toasts', {
      showToast: 'showToast',
    }),
    ...mapActions('orders', ['clearSelectedOrders']),
    next: async function () {
      try {
        if(this.isLoading) return
        let hideDelay = 5000
        this.isLoading = true
        const response = await this.generateProtocol()
        const alert_options = this.alertMessageHandlerCreate(
          response.message,
          hideDelay
        )
        this.$ShowAlert(alert_options)
        const responses_pictures = await this.uploadFileList(alert_options.prt_id)        
        this.toastMessageHandlerUploadFile(responses_pictures, hideDelay)
        this.clearSelectedOrders()
        setTimeout(() => {
          window.location.pathname = '/protocolos'
        }, hideDelay + 500)
      } catch (error) {
        this.$ShowAlert({
          variant: 'error',
          message: error.message,
          hideDelay: 2000
        })                
      } finally {
        this.isLoading = false        
      }
    },
    alertMessageHandlerCreate: function (response_message, hideDelay) {
      let protocol_cod = ''
      let alert_variant = 'success'
      let alert_title = 'Msg padrão'
      let alert_message = ''
      let alert_submessage = ''
      response_message = response_message
      if (response_message.includes('gerado com sucesso')) {
        alert_title = this.$t('alerts.protocoloGerado.titulo')
        protocol_cod = response_message.split('(')[1]
        protocol_cod = protocol_cod.split(')')[0]

        alert_message = this.$t('alerts.protocoloGerado.subTitulo')
        alert_submessage = `${this.$t(
          'alerts.protocoloGerado.extra'
        )}: <b>${protocol_cod}</b>`
      }

      let options = {
        message: alert_message,
        title: alert_title,
        submessage: alert_submessage,
        variant: alert_variant,
        prt_id: protocol_cod,
        hideDelay,
      }

      return options
    },
    toastMessageHandlerUploadFile: function (responses) {
      let options = {
        variant: 'warning',
        message: 'Msg padrão',
      }
      responses.forEach((response) => {
        let data = response.data
        if (!data.hasOwnProperty('msg')) return
        if (!data.msg.hasOwnProperty('message')) return
        if (data.msg.message.includes('efetuado com sucesso')) {
          options.message = this.$t('etapas.fotos.uploadSucesso')
          options.variant = 'success'
        }
        this.showToast(options)
      })
    },
    verifyHasObjectValuesAndKeys(object) {      
      if (!object) return false
      if (Object.keys(object).length === 0) return false
      if (Object.values(object).length === 0) return false
      if (Object.values(object).every((value) => value === '' || value === null)) return false
      return true     
    },
    cancel: function () {
      this.$router.go(-1)
    },
    getTypeBoleto() {
      return typesPayment.BOLETO
    }
  },
  computed: {
    ...mapGetters('create_protocol', {
      dados_gerais: 'getDadosGerais',
      dados_item: 'getDadosItens',
      picture_list: 'getPictureList',      
      bankData: 'getBankData'
    }),
    ...mapGetters('brand', { brand: 'getBrand' }),
    dados_itens: function () {
      if (Array.isArray(this.dados_item)) {
        return this.dados_item
      }
      return [this.dados_item]
    },
  },
  created: async function () {
    this.isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)
    this.brandName = this.brand.name.replace(/\s/g, '').toLowerCase()   
    this.setCurrentStep(5) 
    if (
      !this.dados_itens.length ||
      !this.dados_itens[0].hasOwnProperty('produtoNome')
    ) {
      this.showToast({
        variant: 'warning',
        message: this.$t('erro.carregar'),
      })
      return this.$router.push({
        path: '/produtos-adquiridos-fora-da-loja-online/produto',
      })
    }
    if (!this.dados_gerais.hasOwnProperty('nomeCliente')) {
      this.showToast({
        variant: 'warning',
        message: this.$t('erro.carregar'),
      })
      return this.$router.push({
        path: '/produtos-adquiridos-fora-da-loja-online/comprador',
      })
    }
  },  
}
</script>
<style lang="scss">
.confirm-protocol {
  display: flex;
  flex-wrap: wrap;
  h3 {
    margin: 0;
    margin-bottom: var(--space__0-75x);
    color: var(--color-status--success);
  }
  > div.produtos {
    width: 70%;
    @media (max-width: 576px) {
      width: 100%;
    }
    .produto-container {
      width: 60%;
      height: fit-content;

      line-height: 1rem;
      margin: var(--space__0-25x) 0;

      padding: var(--space__0-75x);
      border: 1px solid var(--color-neutral__30);
      border-radius: var(--border-radius-md);

      @media (max-width: 576px) {
        width: 100%;
      }
      .produto > div {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        margin-top: var(--space__0-25x);
        b {
          min-width: 20%;
          text-align: right;
          margin-right: var(--space__0-25x);
          @media (max-width: 576px) {
            min-width: 40%;
          }
        }
        &.detalhes {
          margin-top: var(--space__0-75x);
        }
      }
    }
  }
  > div.endereco {
    width: 30%;
    padding: var(--space__0-75x);
    border: 1px solid var(--color-neutral__30);
    border-radius: var(--border-radius-md);
    line-height: 1rem;

    @media (max-width: 576px) {
      width: 100%;
      margin-top: var(--space);
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-start;
      margin-top: var(--space__0-25x);
      b {
        min-width: 25%;
        text-align: right;
        margin-right: var(--space__0-25x);
      }
      &.detalhes {
        margin-top: var(--space__0-75x);
      }
    }
  }
  .actions {
    margin-top: var(--space);
    > button:first-of-type {
      margin-right: var(--space);
    }
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;    
  }
  .picture-preview {
    display: block !important;
  }
  .preview {
    flex-wrap: wrap;
    display: flex;
    margin-top: var(--space);
    @media (max-width: 576px) {
      justify-content: space-around;
    }

    .picture-container {
      background: var(--color-neutral__03);
      box-shadow: var(--shadow--sm) var(--color-neutral__30);
      display: flex;
      align-items: center;
      position: relative;
      max-height: 150px;
      @media (min-width: 576px) {
        &:not(:last-of-type) {
          margin-right: var(--space);
          margin-bottom: var(--space);
        }
      }
      @media (max-width: 576px) {
        width: 46%;
        margin-bottom: var(--space);
      }
      .actions {
        position: absolute;
        right: var(--space__0-5x);
        top: var(--space__0-5x);
        display: flex;
        flex-direction: column;
        width: 35px;
        .action {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          width: 100%;
          cursor: pointer;
          background: var(--color-neutral__03);
          box-shadow: var(--shadow--sm) var(--color-neutral__30);
          border-radius: var(--border-radius-md);
          &:hover {
            background: var(--color-neutral__07);
          }
          &:not(:last-of-type) {
            margin-bottom: var(--space__0-5x);
          }
        }
      }
      .picture {
        height: auto;
        max-width: 150px;
        max-height: 150px;        
        display: flex;
        > img {
          width: 100%;
          min-height: 150px;
          height: auto;
          border-radius: var(--border-radius-md);
        }
      }
    }
  }
  #dass-button-tertiary-close-umbro {
    color: var(--theme-color) !important;    
  }
}
</style>
