<template>
  <div :class="`login-root login-theme-${brand.class_name}`">
    <div class="login-image">
      <img
        v-if="is_mobile"
        :alt="`${brand.name} Logo`"
        :src="requireImage(`theme/${brand.folder}/fundo_login_mobile.png`)"
      />
      <img
        v-else
        :alt="`${brand.name} Logo`"
        :src="requireImage(`theme/${brand.folder}/fundo_login.png`)"
      />
    </div>
    <div class="fundo">
      <div class="login-form">
        <form>
          <div class="logo-container">
            <img
              :alt="`${brand.name} Logo`"
              :src="requireImage(`theme/${brand.folder}/logo.png`)"
            />
          </div>

          <h4>{{ $t('login.titulo') }}</h4>
          <dass-button
            :class="this.isSafari ? `dass-button-acesso-rapido-${brandName} button-acesso-rapido` : 'button-acesso-rapido'"
            variant="primary"
            type="button"
            @click="
              (e) => {
                e.preventDefault()
                toogleModalQuickAccess(true)
              }
            "
          >
            {{ $t('login.entrarSemCadastro') }}
          </dass-button>
        </form>
        <div class="duvidas">
          <div>
            <b>
              <span> {{ $t('login.duvidas') }}? </span>
              <dass-link
               :to="redirectContactUs()"
              >
                 {{ $t('dass.cliqueAqui') }}
              </dass-link>
            </b>
          </div>
          <div>
            <b>
              <span> {{ $t('login.jaTenhoCodigo') }} </span>
              <dass-link  @click="toogleModalVerifyCode(true)">
                {{ $t('dass.cliqueAqui') }}
              </dass-link>
            </b>
          </div>
        </div>
      </div>
    </div>

    <modal-quick-access></modal-quick-access>
    <modal-verify-code></modal-verify-code>
  </div>
</template>
<script>
import ModalQuickAccess from '../components/organisms/login/ModalQuickAccess'
import ModalVerifyCode from '../components/organisms/login/ModalVerifyCode'
import requireImage from '../helpers/functions/requireImage'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  components: { ModalQuickAccess, ModalVerifyCode },
  computed: {
    ...mapGetters('brand', { brand: 'getBrand' }),
  },
  data: () => ({
    formData: {
      login: '',
      password: '',
    },
    is_mobile: false,
    isSafari: false,
    brandName: ''    
  }),

  methods: {
    requireImage,
    ...mapActions('modals', [
      'toogleModalQuickAccess',
      'toogleModalVerifyCode',
    ]),
    ...mapActions('session', ['doLogout']),
    redirectContactUs() {      
      const brand = this.brand.name.replace(/\s/g, '').toLowerCase()
      if(brand ==  'fila') {
        return `https://www.${brand}.com.br/institucional/fale-conosco`
      }      
      return `https://www.${brand}.com.br/fale-conosco`      
    }
  },
  created: function () {
    let current_width = document.body.clientWidth
    if (current_width < 576) this.is_mobile = true
    this.isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)    
    this.brandName = this.brand.name.replace(/\s/g, '').toLowerCase()  
    this.doLogout()
  },  
}
</script>

<style lang="scss">
.login-root {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .login-image {
    background: var(--color-secondary--blue__lighter);
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      @media (max-width: 576px) {
        width: auto;
        height: 100%;
      }
    }
  }

  .fundo {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 576px) {
      justify-content: center;
      align-items: center;
    }
    .login-form {
      border: 1px solid var(--theme-gray_light);
      border-radius: 20px 0px 0px 20px;

      background: var(--theme-white);
      width: 60%;
      max-width: 400px;
      @media (max-width: 576px) {
        width: 90%;
                
        border-radius: 20px;
      }
      form {
        padding: var(--space__3-5x);

        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 88%;
        @media (max-width: 576px) {
          justify-content: flex-start;
          padding: var(--space__1-5x);
          padding-top: 15%;
        }
        .logo-container {
          display: flex;
          justify-content: center;
          width: 60%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        h4 {
          margin: 0;
          margin-top: var(--space__1-5x);
        }
        .dass-button {
          width: 100%;
          margin: var(--space__0-75x) 0;          
        }
        .dass-button-acesso-rapido-newbalance {
          transition: background-color 0.3s ease !important;
          &:hover {
            background-color: #E5788B !important;
          }
        }
        .dass-button-acesso-rapido-umbro {
          transition: border 0.3s ease !important;
          &:hover {
            border: 2px solid #737373 !important;
          }
        }
        .dass-button-acesso-rapido-fila {
          transition: background-color 0.3s ease !important;
          &:hover {
            background-color: #758396 !important;
          }
        }
      }
      .duvidas {
        width: 100%;
        text-align: center;
        > div:last-of-type {
          margin-top: var(--space__0-75x);
          font-size: 0.8rem;
          line-height: 1rem !important;
          span {
            margin-right: var(--space__0-5x);
          }
        }
      }
    }
  }

  &.login-theme-umbro {
    .login-form {
      border: none;
      background-color: var(--theme-color);

      @media (max-width: 576px) {
        height: 50%;
        padding-bottom: 2rem;
      }

      h4 {
        color: var(--theme-white);
      }

      .logo-container {
        width: 30% !important;
      }

      .dass-button {
        background-color: var(--theme-white) !important;
        color: var(--theme-color);
      }
      .dass-link {
        color: var(--theme-white) !important;
      }
    }

    .form-group {
      position: relative;
      margin-bottom: 1rem;
      label {
        position: absolute;
        z-index: 15;
        background: var(--theme-color);
        top: -0.6rem;
        left: 0.5rem;
        padding: 0 0.5rem;
        font-size: 0.8rem;
      }
      input {
        background-color: var(--theme-color) !important;
        color: var(--theme-white);
      }
    }
  }
}
</style>
