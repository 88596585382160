<template>
  <customer-view-template>
    <stepper />
    <div class="product-data">
      <form action="" @submit="formSubmit">
        <div>
          <h3>{{ $t('etapas.produto.tituloLoja') }}:</h3>
          <dass-input-text
            required
            type="text"
            v-model="form_data.loja.loja_compra"
            :label="$t('etapas.produto.loja') + ':'"
          />
          <dass-input-text
            required
            type="text"
            v-model="form_data.loja.cidade"
            :label="$t('etapas.produto.cidadeLoja') + ':'"
          />
        </div>
        <div>
          <h3>{{ $t('etapas.produto.tituloCompra') }}:</h3>

          <dass-input-text
            required
            type="tel"
            id="dass-purschase-price"
            v-model="form_data.compra.valor"
            v-money="moneyMask"
            :label="$t('etapas.produto.valorCompra') + ':'"
            @blur="verifyPurchasePrice"
          />
          <b v-if="invalidPriceField">
            <small>{{ this.$t('errors.purchasePrice') }}</small>
          </b>
          <dass-input-text
            v-if="!this.isSafari"
            required
            type="date"                             
            v-model="form_data.data_compra"    
            :label="$t('etapas.produto.dataCompra') + ':'"
          />
          <dass-input-text
            v-if="this.isSafari && isMac"
            required
            id="dass-date-mac"
            type="tel"
            placeholder="dd/mm/aaaa"
            v-mask="'##/##/####'"                    
            v-model="form_data.data_compra"
            @blur="verifyDate"    
            :label="$t('etapas.produto.dataCompra') + ':'"
          />
          <b v-if="invalidDateField">
            <small>{{ this.$t('errors.invalidDate') }}</small>
          </b>
          <div v-if="this.isSafari && !isMac" class="dass-form-control dass-input-text">
            <label for="dassDateSafari" class="dass-input-text--label dass-form-control--label">{{$t('etapas.produto.dataCompra') + ':'}}</label>
            <input              
              required
              type="date"                           
              v-model="form_data.data_compra"              
              name="dassDateSafari"
              class="dass-input-text--input dass-form-control--input"   
              id="dass-date-safari"       
            />
          </div>
          <dass-input-text
            required
            type="tel"
            v-mask="'##############'"
            v-model="form_data.compra.nota_fiscal"
            :label="$t('etapas.produto.numeroNF') + ':'"
          />

          <dass-select
            name="select-message"
            required
            :label="$t('etapas.produto.motivo') + ':'"
            :options="motivos"
            @change="
              (val) => {
                form_data.compra.motivo = val
              }
            "
          >
          </dass-select>
        </div>
        <div>
          <h3>{{ $t('etapas.produto.tituloProdutoDef') }}:</h3>

          <dass-input-text
            required
            type="text"
            v-model="form_data.produto.produto"
            :label="$t('etapas.produto.produto') + ':'"
          />
          <dass-input-text
            required
            type="text"
            v-model="form_data.produto.cor"
            :label="$t('etapas.produto.cor') + ':'"
          />
          <dass-input-text
            required
            type="text"
            v-model="form_data.produto.tamanho"
            v-mask="'XXX'"
            :label="$t('etapas.produto.tamanho') + ':'"
          />
        </div>
        <div>
          <h3>{{ $t('etapas.produto.observacoes') }}:</h3>
          <dass-textarea
            type="text"
            required
            @change="
              (val) => {
                if (typeof val == 'object') return
                form_data.troca.detalhes = val
              }
            "
            :label="$t('etapas.produto.observacoes') + ':'"
          />
        </div>
        <div class="actions">
          <dass-button
            :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"
            :id="`dass-button-tertiary-close-${this.brandName}`"
            variant="tertiary"
            type="button"
            @click="cancel"
          >
            <dass-icon token="icon-content-clear" size="30"></dass-icon>
            {{ $t('dass.cancelar') }}
          </dass-button>

          <dass-button :class="this.isSafari ? `dass-button-primary-${this.brandName} button` : 'button'" variant="primary" type="submit">
            {{ $t('dass.proximo') }}
            <dass-icon
              token="icon-navigation-arrow_forward_ios"
              size="30"
            ></dass-icon>
          </dass-button>
        </div>
      </form>
      <dass-divider></dass-divider>
    </div>
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import Stepper from '../components/organisms/customer_view_template/Stepper'

import {
  DassCheckbox,
  DassInputText,
  DassDivider,
  DassButton,
  DassIcon,
  DassTextarea,
} from 'ds-ui'

import { VMoney } from 'v-money'
import { moneyMask } from '../helpers/globalfilters/defaultMoneyMask'
import { mapGetters, mapActions } from 'vuex'


export default {
  name: 'ProductData',
  props: {
    ecommerce: Boolean,
  },
  components: {
    CustomerViewTemplate,
    DassInputText,
    DassDivider,
    DassButton,
    DassIcon,
    DassTextarea,
    Stepper,
  },
  directives: { money: VMoney },
  data: () => ({
    moneyMask,
    isSafari: false,
    isMac: false,
    invalidDateField: false,
    invalidPriceField: false,
    brandName: '',
    form_data: {
      loja: {
        cnpj: '',
        loja_compra: '',
        cidade: '',
        endereco: '',
        telefone: '',
      },
      compra: {
        valor: '',
        data_compra: '',
        nota_fiscal: '',
        motivo: '',
      },
      produto: {
        produto: '',
        cor: '',
        tamanho: '',
      },
      troca: {
        reembolso: false,
        produto: '',
        cor: '',
        tamanho: '',
        detalhes: '',
      },
    },
  }),
  computed: {
    ...mapGetters('create_protocol', {
      subjects: 'getSubjects',
    }),
    ...mapGetters('brand', {
      brand: 'getBrand',
    }),
    motivos: function () {
      let motivos = []
      this.subjects.forEach((reason) => {
        if (!reason.ASS_NOME) return
        let temp = {}
        temp.text = reason.ASS_NOME
        temp.value = reason.ASS_ID

        motivos.push(temp)
        return temp
      })
      motivos.unshift({
        text: 'Selectione um Motivo',
        value: '',
        disabled: true,
        selected: true,
      })

      return motivos
    },
  },
  methods: {
    ...mapActions('create_protocol', [
      'loadSubjects',
      'setCurrentStep',
      'createItem',
      'createDados',
      'generateProtocol',
    ]),
    ...mapActions('toasts', {
      showToast: 'showToast',
    }),
    invertCheckboxReembolso() {
      return (this.form_data.troca.reembolso = !this.form_data.troca.reembolso)
    },
    formSubmit: function (e) {
      e.preventDefault()

      this.verifyPurchasePrice()
      if(this.invalidPriceField) return

      let motivo_selected = this.form_data.compra.motivo
      let motivo = this.subjects.find((reason) => {
        return reason.ASS_ID == motivo_selected
      })
      motivo = motivo

      let observacao = this.form_data.troca.detalhes
      observacao = observacao.replaceAll('\n', '')

      let valor = this.form_data.compra.valor
      valor = this.$filters.fromMoney(valor)
      
      let dataCompraLoja = this.form_data.data_compra
    
      dataCompraLoja = this.$filters.toDateBRL(dataCompraLoja)
    
      let item = {
        desejo_reembolso: this.form_data.troca.reembolso,
        desejo_troca: this.form_data.troca,

        itemDevolucao: 1,
        agrupador: '',
        produtoCodigo: 0,
        produtoNome: this.form_data.produto.produto,
        produtoCor: this.form_data.produto.cor,
        produtoValor: parseFloat(valor),
        motivoCodigo: parseInt(motivo.ASS_ID || 0),
        motivoNome: motivo.ASS_NOME || '',
        desejoCodigo: 1,
        desejoNome: '',
        gradeCodigo: '',
        gradeTamanho: this.form_data.produto.tamanho,
        tamanhoTroca: this.form_data.troca.tamanho,
        empresaCodigo: parseInt(this.brand.mar_cod || ''),
        filialCodigo: 1,
        sequenciaFat: 0,
        itemFatSequencia: 0,
        numeroNF: parseInt(this.form_data.compra.nota_fiscal),
        quantidadeNF: 0,
        qtdDevolucao: 1,
        observacao: observacao,

        nomeLoja: this.form_data.loja.loja_compra,
        cnpjLoja: this.form_data.loja.cnpj.replace(/[^0-9]+/g, ''),
        telefoneLoja: this.form_data.loja.telefone,
        dataCompraLoja: dataCompraLoja,
        enderecoLoja: this.form_data.loja.endereco,
        cidadeLoja: this.form_data.loja.cidade,
        reclamacao: true,
      }
      
      this.createItem(item)
      this.next()
    },
    next: function () {
      this.$router.push({
        path: '/produtos-adquiridos-fora-da-loja-online/comprador',
      })
    },
    cancel: function () {
      this.$router.push({
        path: '/home',
      })
    },    
    verifyPurchasePrice() {
      if(this.form_data.compra.valor == 'R$ 0,00') {
        this.invalidPriceField = true
        document.getElementById('dass-purschase-price').focus()        
      } else {
        this.invalidPriceField = false       
      }
    },
    verifyDate() {
      const isValidDate = this.$filters.validateDate(this.form_data.data_compra)
      
      if(!isValidDate) {
        this.invalidDateField = true
        document.getElementById('dass-date-mac').focus()
      } else {
        this.invalidDateField = false
      }
    }
  },

  created: function () {
    this.isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent)  
    this.isMac = navigator.userAgent.includes('Macintosh') 
    this.brandName = this.brand.name.replace(/\s/g, '').toLowerCase()   
    this.setCurrentStep(1)

    let requestBody = {
      ass_id: '',
      cto_id: 1,
      ass_mot_ope: 'N',
      ass_ativo: 'S',
      ass_nome: '',
    }
    this.loadSubjects(requestBody)
  }, 
}
</script>

<style lang="scss">
.product-data {
  text-align: left;
  small {
    color: red;
    margin-left: var(--space__0-25x);
  }
  form {
    display: flex;
    flex-wrap: wrap;

    > div {
      padding: var(--space);
      width: 25%;
      @media (max-width: 576px) {
        width: 100%;
      }
      > div {
        margin-top: var(--space__0-75x);
        label {
          font-weight: bold !important;
        }
        div.dass-input-text--input-wrapper,
        div.dass-select--select-wrapper {
          margin-top: 0;
        }
      }
    }
    .actions {
      > button:first-of-type {
        margin-right: var(--space);
      }
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;      
    }
    #dass-date-safari {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
  #dass-button-tertiary-close-umbro {
    color: var(--theme-color) !important;    
  }
}
</style>
