<template>
  <customer-view-template>
    <div class="home">
      <order-list></order-list>
      <div class="produtos-fora-loja-online">
        <div class="container">
          <div>
            <b> {{ $t('home.produtosFisicos') }} </b>
          </div>
          <div>
            <dass-button
              class="button-acesso-rapido"
              variant="secondary"
              type="button"
              @click="
                () => {
                  $router.push({
                    path: '/produtos-adquiridos-fora-da-loja-online/produto',
                  })
                }
              "
            >
              {{ $t('dass.cliqueAqui') }}
            </dass-button>
          </div>
          <div
            v-show="count_selected > 0"
            class="solicitacoes"
            @click="toggleSidebar(true)"
          >
            <div class="titulo">
              <dass-badge type="number" variant="secondary">
                <template #default> {{ count_selected }} </template>
              </dass-badge>
              <span class="texto"> {{ $t('home.solicitacoes') }} </span>
            </div>
            <div class="abrir-lista">
              <dass-link> {{ $t('home.abrirLista') }} </dass-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sidebar />
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import OrderList from '../components/organisms/home/OrderList'
import Sidebar from '../components/organisms/home/sidebar/Sidebar'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Home',
  components: { CustomerViewTemplate, OrderList, Sidebar },
  computed: {
    ...mapGetters('orders', {
      orders: 'getOrders',
      selected_orders: 'getSelectedOrders',
    }),
    count_selected: function () {
      let count = 0
      count = Object.keys(this.selected_orders).length || 0
      return count
    },
  },
  methods: {
    ...mapActions('orders', ['selectOrder', 'toggleSidebar']),
  },
}
</script>

<style lang="scss">
.home {
  text-align: left;
  padding-bottom: 60px;
  .produtos-fora-loja-online {
    background: var(--theme-white);
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;    
    z-index: 11; 
    width: 100%;
    display: flex;
    justify-content: center;
    border: var(--theme-border);
    border-color: var(--theme-gray_light);
    .container {
      width: 80%;
      display: flex;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        &:first-of-type {
          padding-right: var(--space);
        }
        padding: var(--space__0-5x);
        button {
          padding: initial;

          width: 100%;
          height: 100%;
          padding: var(--space__0-5x) var(--space__2x);
        }
      }
      @media (max-width: 1024px) {
        padding: var(--space);

        width: 100%;
        > div {
          width: 50%;
          line-height: 1rem;
          b {
            color: var(--theme-gray);
          }
          button {
            padding: var(--space__0-75x) var(--space);
          }
        }
      }
    }
  }
  .solicitacoes {
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--theme-white);
    border-radius: var(--theme-border-radius);

    width: 40%;
    max-width: 400px;

    margin-left: auto;

    height: 4rem;
    align-items: center;

    padding: 0 var(--space__2x) !important;
    justify-content: space-between;

    @media (max-width: 1024px) {
      position: fixed;
      bottom: 7rem;
      height: auto;
      padding: var(--space) !important;
      width: auto !important;
      right: var(--space__1-5x);
    }
    .titulo {
      text-transform: uppercase;
      font-weight: bold;
      .dass-badge {
        margin-right: var(--space__0-5x);
        @media (max-width: 1024px) {
          position: absolute;
          left: -10px;
          top: -10px;
        }
      }
      .dass-badge--element {
        position: initial !important;
      }
      .texto {
        vertical-align: middle;
      }
    }

    .dass-link {
      color: var(--theme-white) !important;
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
}

.theme-umbro {
  .produtos-fora-loja-online {
    border: none !important;
    background-color: var(--theme-color) !important;
    b {
      color: var(--theme-white);
    }
  }
}
</style>