import { clientAPI } from '../configs/configs'
import * as env from '../configs/configs'

const urlBase = env.urlGeneral + '/AddressBook'
export default class Address {
  static async getDataCeps(cep) {    
   
       
    return new Promise((res, rej) => {
      try {
        clientAPI
          .get(`${urlBase}/cep/${cep}`)
          .then((response) => {
            res(response.data)
          })
      } catch (err) {
        rej(err)
      }
    })
  }
}