import Login from '@/services/login'
import * as types from './mutation-types'
import Cookies from 'js-cookie'
import capitalizeAllWords from '../../../helpers/normalizers/capitalizeAllWords'

import { MOD_SUBMODULO } from '../../../helpers/constants/authentication'
import store from '../..'
import isJson from '../../../helpers/functions/isJson'

export const loadSessionFromCookie = async ({ commit }) => {
  let email = Cookies.get('usu_email') || ''
  commit(types.SET_USU_EMAIL, { email })
  return { email }
}

export const setUsuEmail = async ({ commit }, { email, cookies }) => {
  if (cookies) Cookies.set('usu_email', email, { expires: 7 })
  commit(types.SET_USU_EMAIL, { email })
}

export const sendEmailCode = async ({ commit }, email) => {
  if (email !== '') await setUsuEmail({ commit }, { email, cookies: true })

  const brand = store.getters['brand/getBrand'] || {}

  let request_body = {
    origem: MOD_SUBMODULO.mod_sub_modulo,
    brand: brand.id,
    email,
  }

  const response = await Login.sendEmailCode(request_body)
  return response
}

export const verifyCode = async ({ commit }, { code, email }) => {
  let current_email = store.getters['session/getUsuEmail'] || {}
  const brand = store.getters['brand/getBrand'] || {}

  if (!Object.keys(current_email).length || email) {
    current_email = email
  }

  let request_body = {
    origem: MOD_SUBMODULO.mod_sub_modulo,
    brand: brand.id,
    email: current_email,
    tokenCode: code,
  }

  const response = await Login.verifyCode(request_body)
  return response
}

export const doLogout = async ({ commit }) => {
  const response = await Login.doLogout()
  Cookies.remove('usu_email')
  Cookies.remove('user')
  Cookies.remove('user_address')
  commit(types.DO_LOGOUT)
  return response
}

export const handlerUser = async ({ commit }) => {
  const user_session = store.getters['session/getUser'] || {}
  const address_session = store.getters['session/getUserAddress'] || {}
  if (
    user_session.hasOwnProperty('cpf') &&
    user_session.cpf != '' &&
    address_session.hasOwnProperty('cep') &&
    address_session.cep != ''
  )
    return { user: user_session, address: address_session }

  let user = Cookies.get('user') || false
  let address = Cookies.get('user_address') || false

  if (typeof user == 'string' && typeof address == 'string') {
    user = isJson(user)
    address = isJson(address)
    if (
      user &&
      address &&
      user.hasOwnProperty('cpf') &&
      address.hasOwnProperty('cep')
    ) {
      setUser({ commit }, user)
      setAddres({ commit }, address)
      user = { user, address }
      return user
    }
  }

  user = await loadUserDataFromProtocolsB2C({ commit })
 
  if(!user) {
    const usu_email = store.getters['session/getUsuEmail'] || ''
    const dataConsumer = store.getters['create_protocol/getDataConsumer'] || []   
    if(dataConsumer.length > 0) {
      user = {}
      user.user = {
        cpf: dataConsumer[0].CON_CPF || '',
        nome: dataConsumer[0].CON_NOME || '',
        telefone: dataConsumer[0].CON_FONE_CEL || '',
        usu_email,
      }
      user.address = {
        cep: dataConsumer[0].CON_CEP || '',
        endereco: dataConsumer[0].CON_ENDERECO || '',
        numero: dataConsumer[0].CON_ENDNUM || '',
        complemento: dataConsumer[0].CON_COMPL || '',
        bairro: dataConsumer[0].CON_BAIRRO || '',
        cidade: dataConsumer[0].CON_CIDADE || '',
        uf: dataConsumer[0].CON_UF || '',
      }
    }    
  }
  setUser({ commit }, user.user)
  setAddres({ commit }, user.address)

  return user
}

export const setUser = async ({ commit }, user_data) => {
  const user_session = store.getters['session/getUser'] || {}
  let user = Object.assign({}, user_session, user_data)
  Cookies.set('user', JSON.stringify(user), { expires: 7 })

  commit(types.SET_USER, { user })
}

export const setAddres = async ({ commit }, address_data) => {
  const address_session = store.getters['session/getUserAddress'] || {}
  let address = Object.assign({}, address_session, address_data)
  Cookies.set('user_address', JSON.stringify(address), { expires: 7 })

  commit(types.SET_USER_ADDRESS, { address })
}

const loadUserDataFromProtocolsB2C = async ({ commit }) => {
  const usu_email = store.getters['session/getUsuEmail'] || ''

  let protocols = (await store.getters['protocols/getProtocols']) || []
  if (!protocols.length)
    protocols = await store.dispatch('protocols/loadProtocols')
  if (!protocols.length) return false
  const protocol = protocols[0]

  let user = {
    cpf: protocol.CON_CPF || '',
    nome: protocol.CON_NOME || '',
    telefone: protocol.CON_FONE_CEL || '',
    usu_email,
  }
  let address = {
    cep: protocol.CON_CEP || '',
    endereco: protocol.CON_ENDERECO || '',
    numero: protocol.CON_ENDNUM || '',
    complemento: protocol.CON_COMPL || '',
    bairro: protocol.CON_BAIRRO || '',
    cidade: protocol.CON_CIDADE || '',
    uf: protocol.CON_UF || '',
  }
  return { user, address }
}
