<template>
  <div class="customer-view-stepper">
    <dass-stepper
      v-bind="args"
      :currentStep="current_step"
      :steps="steps"
      @change="change"
    />
  </div>
</template>

<script>
import { DassStepper } from 'ds-ui'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'stepper',
  components: { DassStepper },
  emits: ['changeCurrentStep'],
  computed: {
    ...mapGetters('create_protocol', {
      current_step: 'getCurrentStep',      
    }),
    steps: function () {
      const steps = [
        {
          label: this.$t('etapas.itens'),
          name: 'step-items',
        },
        {
          label: this.$t('etapas.endereco'),
          name: 'step-address',
        },
        {
          label: this.$t('etapas.fotos.titulo'),
          name: 'step-fotos',
        },
        {
          label: this.$t('dass.bankData'),
          name: 'step-bank-data',
        },
        {
          label: this.$t('etapas.confirmacao'),
          name: 'step-confirm',
        },
      ]
      return steps
    },
  },  
  methods: {
    change(currentStep) {
      this.$emit('changeCurrentStep', currentStep)
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.customer-view-stepper {
  margin-bottom: var(--space);
}
</style>
