<template>
  <div class="t-customer-view">
    <dass-header id="header-top" logoUrl="/home">
      <template v-slot:left-content>
        <router-link :to="`/home`" :title="$t('dass.pgInicial')">
          <div class="logo">
            <img
              :alt="`${brand.name} Logo`"
              :src="requireImage(`theme/${brand.folder}/logo.png`)"
            />
            <div v-if="!is_mobile" class="version-container">
              <small> v: {{ version }} </small>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:right-content>
        <dass-nav>
          <dass-nav-item to="/protocolos" id="link-to-protocols">
            {{ $t('protocolos.protocolos') }}
          </dass-nav-item>
          <dass-nav-item to="/home" id="link-to-home">
            {{ $t('dass.pgInicial') }}
          </dass-nav-item>
          <dass-nav-item to="/login" id="btn-logout">
            {{ $t('dass.sair') }}
          </dass-nav-item>
          <dass-nav-item>
            <language-selector></language-selector>
            <div class="version" v-if="is_mobile">
              <small> v: {{ version }} </small>
            </div>
          </dass-nav-item>
        </dass-nav>
      </template>
    </dass-header>
    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { DassHeader, DassNav, DassNavItem } from 'ds-ui'
import { mapGetters, mapActions } from 'vuex'

import LanguageSelector from '../organisms/customer_view_template/LanguageSelector'
import { version } from '../../../package'
import requireImage from '../../helpers/functions/requireImage'

export default {
  name: 'customer-view-template',
  components: { DassHeader, DassNav, DassNavItem, LanguageSelector },
  data: () => ({
    version: '',
    is_mobile: false,
  }),
  created: function () {
    this.version = version || 0
    let current_width = document.body.clientWidth
    if (current_width < 576) this.is_mobile = true
  },
  methods: {
    requireImage,
  },
  computed: {
    ...mapGetters('brand', { brand: 'getBrand' }),
  },
}
</script>

<style lang="scss">
.dass-header {
  background-color: var(--theme-white) !important;
  border-bottom: 2px solid var(--theme-gray_light);
  .logo {
    position: relative;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .dass-nav {
    background-color: var(--theme-white);
    .dass-link {
      color: var(--theme-gray) !important;
      font-weight: bold;
      transition: color 200ms;
      &:hover,
      &:active,
      &:focus {
        color: var(--theme-black) !important;
        outline: none;
        background-color: var(--theme-white);
      }
    }
  }
  details.dass-language-selector {
    &[open],
    &:focus,
    &:active,
    &:focus-within {
      outline: none !important;
      > summary {
        background-color: var(--theme-white) !important;
      }
    }
  }
}
@media (min-width: 576px) {
  .dass-header {
    padding: 0 10vw;
  }
  .logo {
    max-width: 200px;
  }
  .version-container {
    color: var(--theme-gray);
    font-weight: bold;
    position: absolute;
    bottom: -10px;
    right: -35px;
  }
  .dass-nav .version {
    display: none;
  }
}
@media (max-width: 576px) {
  .logo {
    display: flex;
    width: 60%;
  }
  .dass-nav {
    background: var(--theme-white) !important;
    .dass-nav--toggle-button {
      background: var(--theme-white) !important;
      color: var(--theme-black) !important;
      &:focus,
      &:active,
      &:focus-within {
        outline: none !important;
      }
    }
    .dass-nav--menu {
      background: var(--theme-white) !important;
    }
  }
  .dass-nav .version {
    padding: var(--space);
    position: absolute;
    right: 0;
    bottom: 0;
    color: var(--theme-gray);
  }
  .version-container {
    display: none;
  }
}

.page-content {
  padding: var(--space);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    width: 80%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
.t-customer-view {
  .dass-nav--menu--item {
    display: flex !important;
    align-items: center;
  }
}

.theme-umbro .t-customer-view {
  .dass-header {
    padding: 0.5rem 10vw;
    background-color: var(--theme-color) !important;
    .logo {
      max-width: 70px !important;
    }
    .dass-nav {
      background-color: var(--theme-color) !important;
      li a {
        color: var(--theme-white) !important;
        &:hover {
          color: var(--theme-white) !important;
          text-decoration: underline !important;
          background-color: var(--theme-color) !important;
        }
      }
    }
  }
}
</style>
